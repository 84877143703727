//TODO: remove unnecessary imports
import { lazy } from "react";
//import Crypto from "../Components/Dashboard/Crypto";

// //Application

// const SamplePage = React.lazy(() => import("../Components/Pages/Sample"));

import Default from "../Components/Dashboard/Default";
//import Ecommerce from "../Components/Dashboard/Ecommerce";
//import OnlineCourse from "../Components/Dashboard/OnlineCourse";
//import Social from "../Components/Dashboard/Social";

// //widgets
import Chart from "../Components/Widgets/Chart";
import General from "../Components/Widgets/General";
// //page-layout
// import FooterLight from '../Components/PageLayout/FooterLight';

// import FooterDark from '../Components/PageLayout/FooterDark';

// // Icons
import WeatherIcon from "../Components/Icons/WeatherIcon";
import FeatherIcon from "../Components/Icons/FeatherIcon";
import ThemifyIcon from "../Components/Icons/ThemifyIcon";
import IcoIcon from "../Components/Icons/IcoIcon";
import FontawesomeIcon from "../Components/Icons/FontawesomeIcon";
import FlagIcon from "../Components/Icons/FlagIcon";


// //Application
//import Newproject from "../Components/Application/Project/Newproject";
//import Project from "../Components/Application/Project/Project";
//* Users
import UsersContainer from "../Components/Application/Users";

//* Artist 

//* Products
import ProductsContainer from "../Components/Application/Products";
import AssetContainer from "../Components/Application/Products/Assets";

//* Contributors

import Contributors from "../Components/Application/Contributors";

//* Providers

//* Expenses
import MainExpenses from "../Components/Application/Expenses/Main-Expenses";
import ContributorExpenses from "../Components/Application/Expenses/Contrib-Expenses";

//* Invoices
import InvoicesTable from "../Components/Application/Invoices/InvoicesTable";
import NewInvoice from "../Components/Application/Invoices/NewInvoice";

// //Pages
import SamplePage from "../Components/Pages/Sample";
// // import SearchPage from '../Components/Application/SearchResult'

// //Profile app
import UsersEditContain from "../Components/Application/Profile/UsersEdit";

// //Income
import IncomesContainer from "../Components/Application/Income";
import NewIncome from "../Components/Application/Income/NewIncome";



import ContributorContainer from "../Components/Application/Contributors";
import ContributorsContainer from "../Components/Application/Products/Contributors";



export const routes = [
  // // Dashboard
  {
    path: `${process.env.PUBLIC_URL}/dashboard`,
    Component: <Default />,
    roles: ["admin","staff"],
  },

  ////Users
  {
    path: `${process.env.PUBLIC_URL}/app/users/users-list`,
    Component: <UsersContainer />,
    roles: ["admin", "staff"],
  },

   ////Artists

    ////Products

    {
      path: `${process.env.PUBLIC_URL}/app/products/products-list`,
      Component: <ProductsContainer />,
      roles: ["admin", "staff"],
    },

    {
      path: `${process.env.PUBLIC_URL}/app/products/:id/assets`,
      Component: <AssetContainer />,
      roles: ["admin", "staff"],
    },
    {
      path: `${process.env.PUBLIC_URL}/app/contributors`,
      Component: <Contributors/>,
      roles: ["admin", "staff"],
    },
    {
      path: `${process.env.PUBLIC_URL}/app/products/:id/assets/:assetId/contributors`,
      Component: <ContributorsContainer />,
      roles: ["admin", "staff"],
    },
    {
      path: `${process.env.PUBLIC_URL}/app/income/income-list`,
      Component: <IncomesContainer />,
    },


    ////Invoices
    {
      path: `${process.env.PUBLIC_URL}/app/invoices/invoices-list`,
      Component: <InvoicesTable />,
    },
    {
      path: `${process.env.PUBLIC_URL}/app/income/income-new`,
      Component: <NewIncome />,
    },


    ////Providers

    ////Expenses
    {
      path: `${process.env.PUBLIC_URL}/app/expenses/red-expenses`,
      Component: <MainExpenses />,
      roles: ["admin", "staff"],
    },
    {
      path: `${process.env.PUBLIC_URL}/app/expenses/contributors-expenses`,
      Component: <ContributorExpenses />,
      roles: ["admin", "staff"],
    },

  {
    path: `${process.env.PUBLIC_URL}/widgets/general/:layout`,
    Component: <General />,
  },
  {
    path: `${process.env.PUBLIC_URL}/widgets/chart/:layout`,
    Component: <Chart />,
  },



  {
    path: `${process.env.PUBLIC_URL}/icons/flag_icons/:layout`,
    Component: <FlagIcon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/icons/fontawesome_icon/:layout`,
    Component: <FontawesomeIcon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/icons/ico_icon/:layout`,
    Component: <IcoIcon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/icons/themify_icons/:layout`,
    Component: <ThemifyIcon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/icons/feather_icons/:layout`,
    Component: <FeatherIcon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/icons/weather_icons/:layout`,
    Component: <WeatherIcon />,
  },

  {
    path: `${process.env.PUBLIC_URL}/app/users/account/:layout`,
    Component: <UsersEditContain />,
  },

  // //page
  {
    path: `${process.env.PUBLIC_URL}/pages/sample-page/:layout`,
    Component: <SamplePage />,
  },

];
